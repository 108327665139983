<template>
  <div>
    <v-card v-if="examDetails">
      <v-card-title class="primary white--text">
        {{ examDetails.examVersion.exam.name }}
      </v-card-title>
      <quiz-info :exam-details="examDetails"></quiz-info>
      <quiz-progress :exam-details="examDetails" :currentQuestion="currentQuestion" />
      <quiz-countdown
        v-if="!examDetails.finished"
        :seconds="examDetails.remainingSeconds"
        @timeout="finishExam()"
      ></quiz-countdown>
      <quiz-question
        v-if="currentQuestionData && currentQuestionData.__typename === 'UserAnswer'"
        :questionNumber="questionNumber"
        :user-answer="currentQuestionData"
        :token="token"
        :last="false"
        :key="currentQuestion"
        @changeQuestion="changeQuestion"
        @changeUnsaved="changeUnsaved"
        @doFinishExam="finishExam"
      />
      <quiz-open-question
        v-else-if="currentQuestionData && currentQuestionData.__typename === 'OpenUserAnswer'"
        :questionNumber="questionNumber"
        :user-answer="currentQuestionData"
        :token="token"
        :last="false"
        :key="currentQuestion"
        @changeQuestion="changeQuestion"
        @changeUnsaved="changeUnsaved"
        @doFinishExam="finishExam"
      />
      <!--        key === examDetails.userAnswers.length - 1"-->
      <v-card-text v-if="finished">
        Egzamin został już ukończony z wynikiem
        <strong>{{ examDetails.passed ? 'zaliczony' : 'nie zaliczony' }}</strong>
        <br />
        Twój wynik to: <strong>{{ examDetails.resultInPercent }}%</strong>
        <br />
        <v-alert class="mt-3" color="orange" outlined>
          UWAGA: wynik może się jeszcze zmienić jeżeli pytania otwarte nie zostały sprawdzone!
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <quiz-begin-button :exam-details="examDetails"></quiz-begin-button>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import EXAM_DETAILS_QUERY from '@/graphql/exams/query/TestToolExamDetailsQuery.gql';
import QuizInfo from './Exam/QuizInfo.vue';
import QuizProgress from './Exam/QuizProgress.vue';
import QuizCountdown from './Exam/QuizCountdown.vue';
import QuizQuestion from './Exam/QuizQuestion.vue';
import QuizBeginButton from './Exam/QuizBeginButton.vue';
import QuizOpenQuestion from '@/pages/test-platform/Exam/QuizOpenQuestion.vue';

export default {
  components: { QuizOpenQuestion, QuizBeginButton, QuizQuestion, QuizCountdown, QuizProgress, QuizInfo },
  // components: {QuizBeginButton, QuizCountdown, QuizInfo, QuizQuestion},
  data() {
    return {
      finished: false,
      isLoading: true,
      questionNumber: 0,
      hasUnsaved: false,
    };
  },
  methods: {
    finishExam() {
      this.finished = true;
    },
    changeUnsaved(value) {
      this.hasUnsaved = value;
    },
    changeQuestion(value) {
      this.questionNumber += value;
      if (this.questionNumber >= this.examDetails.userAnswers.length - 1) {
        this.questionNumber = this.examDetails.userAnswers.length - 1;
      }
      if (this.questionNumber < 0) {
        this.questionNumber = 0;
      }
    },
  },
  computed: {
    // eslint-disable-next-line consistent-return
    currentQuestionData() {
      if (!this.examDetails.started) {
        return null;
      }
      if (this.examDetails.finished) {
        return null;
      }

      for (const key in this.examDetails.userAnswers) {
        if (parseInt(key, 10) === this.questionNumber) {
          return this.examDetails.userAnswers[key];
        }
      }
      return null;
    },
    currentQuestion() {
      return this.questionNumber + 1;
    },
    loading() {
      return this.$apollo.loading;
    },
  },
  // comments: { QuizQuestion },
  apollo: {
    examDetails: {
      query: EXAM_DETAILS_QUERY,
      variables() {
        return {
          token: this.token,
        };
      },
      update(data) {
        return data.exam;
      },
    },
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
};
</script>
<style>
.progress-wrapper {
  width: 100%;
}
</style>
