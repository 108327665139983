<template>
  <div v-if="userAnswer">
    <v-card-title>
      {{ userAnswer.question.name }}
    </v-card-title>
    <ShowFile :file="userAnswer.question.file" />
    <v-radio-group v-model="selectedAnswer" v-if="userAnswer.__typename === 'UserAnswer'">
      <v-radio
        v-for="(answer, key) in userAnswer.question.answers"
        :label="answer.name"
        :key="key"
        :value="answer.id"
      ></v-radio>
    </v-radio-group>

    <v-btn class="is-outlined" :loading="loading" :disabled="false" @click="$emit('changeQuestion', -1)">
      Wstecz
    </v-btn>
    <v-btn
      class="is-primary is-outlined"
      v-if="!last"
      :loading="loading"
      :disabled="disableNextButton"
      @click="doAnswer()"
    >
      Dalej
    </v-btn>
    <v-btn class="is-primary is-outlined" v-else :loading="loading" :disabled="!selectedAnswer" @click="doAnswer()">
      Zapisz
    </v-btn>
    <quiz-finish-button @finishExam="finishExam" :token="examDetails.token" v-if="canFinish"></quiz-finish-button>
  </div>
</template>

<script>
import EXAM_DETAILS_QUERY from '@/graphql/exams/query/TestToolExamDetailsQuery.gql';
import ANSWER_MUTATION from '@/graphql/exams/mutation/SendAnswerMutation.gql';
import QuizFinishButton from './QuizFinishButton.vue';
import ShowFile from '@/pages/exam/ShowFile.vue';

export default {
  name: 'QuizQuestion',
  components: { ShowFile, QuizFinishButton },
  data() {
    return {
      loading: false,
      selectedAnswer: null,
      hasUnsaved: false,
    };
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    last: {
      type: Boolean,
      required: true,
    },
    userAnswer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    disableNextButton() {
      if (this.userAnswer.__typename === 'UserAnswer' && !this.selectedAnswer) {
        return true;
      }

      if (this.userAnswer.__typename === 'OpenUserAnswer' && !this.textAnswer) {
        return true;
      }

      return false;
    },
    canFinish() {
      if (this.hasUnsaved) {
        return false;
      }
      if (!this.examDetails.userAnswers) {
        return false;
      }
      return this.examDetails.canBeFinished;
    },
  },
  methods: {
    resolveSelectedAnswer() {
      if (this.userAnswer.questionAnswers.length === 0) {
        return [];
      }
      return this.userAnswer.questionAnswers.map((answer) => answer.id);
    },

    finishExam() {
      this.doAnswer().then(() => {
        this.$emit('doFinishExam');
      });
    },

    doAnswer() {
      this.loading = true;
      const promise = this.$apollo.mutate({
        mutation: ANSWER_MUTATION,
        variables: {
          token: this.token,
          answerId: this.selectedAnswer,
        },
        update: (store, { data: { exam } }) => {
          store.writeQuery({
            query: EXAM_DETAILS_QUERY,
            variables: { token: this.examDetails.token },
            data: { exam },
          });
        },
      });
      promise.then(() => {
        this.loading = false;
        this.hasUnsaved = false;
        this.$emit('changeUnsaved', false);
        this.$emit('changeQuestion', 1);
      });
      return promise;
    },
  },
  mounted() {
    if (this.resolveSelectedAnswer().length > 0) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedAnswer = this.resolveSelectedAnswer()[0];
    }
  },
  apollo: {
    examDetails: {
      query: EXAM_DETAILS_QUERY,
      variables() {
        return {
          token: this.token,
        };
      },
      update: (data) => {
        return data.exam;
      },
    },
  },
};
</script>
<style scoped>
.question-image {
  width: auto;
  max-height: 300px;
}

.buttons .button {
  margin-right: 1rem;
}
</style>
