<template>
  <div v-if="examDetails.userAnswers && examDetails.userAnswers.length > 0">
    <v-progress-linear :value="progress"></v-progress-linear>
  </div>
</template>

<script>
export default {
  name: 'QuizProgress',
  props: {
    examDetails: {
      type: Object,
      required: true,
    },
    currentQuestion: {
      type: Number,
      required: true,
    },
  },
  computed: {
    progress() {
      if (this.examDetails.finished) {
        return 100;
      }
      return (this.currentQuestion / this.examDetails.userAnswers.length) * 100;
    },
  },
};
</script>
