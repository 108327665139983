<template>
  <v-alert border="bottom" color="blue" dense prominent type="success" v-if="!examDetails.finished">
    Na wypełnienie egzaminu masz {{ examDetails.examVersion.maxTime }} minut od jego rozpoczęcia. <br />
    Na egzaminie można uzyskać {{ examDetails.examVersion.maxPoints }} punktów. <br />
    Aby zaliczyć musisz uzyskać {{ examDetails.examVersion.pointThreshold }} punktów.
  </v-alert>
  <v-alert border="bottom" color="blue" dense prominent type="success" v-else> Egzamin został ukończony </v-alert>
</template>

<script>
export default {
  name: 'QuizInfo',
  props: {
    examDetails: {
      type: Object,
      required: true,
    },
  },
};
</script>
