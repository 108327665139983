<template>
  <div>
    <v-btn color="primary" v-if="examDetails.canBeStarted" :loading="loading" @click="askNameSurname()">
      Rozpocznij
    </v-btn>
    <div v-else-if="!examDetails.started">Egzamin nie może być rozpoczęty</div>
  </div>
</template>

<script>
import BEGIN_EXAM_MUTATION from '@/graphql/exams/mutation/BeginExamMutation.gql';
import EXAM_DETAILS_QUERY from '@/graphql/exams/query/TestToolExamDetailsQuery.gql';

export default {
  name: 'QuizBeginButton',
  data() {
    return {
      loading: false,
    };
  },
  props: {
    examDetails: {
      type: Object,
      required: true,
    },
  },
  methods: {
    askNameSurname() {
      this.beginExam();
      // this.$buefy.dialog.confirm({
      //   title: 'Rozpocznij egzamin',
      //   message: `Zaraz rozpocznie się egzamin. Upewnij się że to Twoje dane: <pre>${this.examDetails.participant.lastName} ${this.examDetails.participant.firstName}</pre>`,
      //   cancelText: 'Nie',
      //   confirmText: 'Tak',
      //   type: 'is-success',
      //   onConfirm: () => this.beginExam(),
      // });
    },
    beginExam() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: BEGIN_EXAM_MUTATION,
          variables: {
            token: this.examDetails.token,
          },
          update: (store, { data: { exam } }) => {
            store.writeQuery({
              query: EXAM_DETAILS_QUERY,
              variables: { token: this.examDetails.token },
              data: { exam },
            });
          },
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
