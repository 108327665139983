<template>
  <ConfirmModal
    title="zakończyć egzamin?"
    content="czy jesteś pewien że chcesz zakończyć egzamin ? Nie będziesz mógł zmienić już żadnej odpowiedzi!"
    @confirm="mutate"
    :loading="loading"
  >
    <template v-slot:activator="{ openModal }">
      <v-btn color="orange" @click="openModal" :loading="loading">Zakończ egzamin</v-btn>
    </template>
  </ConfirmModal>
</template>

<script>
import ConfirmModal from '@/components/Common/ConfirmModal.vue';
import { forceFinishExamMutation } from '@/generated/operations';

export default {
  name: 'QuizFinishButton',
  components: { ConfirmModal },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  methods: {
    async mutate() {
      this.loading = true;
      const { success, data, errors } = await forceFinishExamMutation(this, {
        variables: {
          token: this.token,
        },
      });
      this.loading = false;
      console.log(success, data, errors);
    },
  },
};
</script>
