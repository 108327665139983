<template>
  <div v-if="userAnswer">
    <v-card-title>
      {{ userAnswer.openQuestion.name }}
    </v-card-title>
    <ShowFile :file="userAnswer.openQuestion.file" />
    <v-textarea outlined class="mr-1 ml-1 mt-1 mb-1" v-model="answerContent" :counter="1000"></v-textarea>
    <v-btn class="is-outlined" :loading="loading" :disabled="false" @click="$emit('changeQuestion', -1)">
      Wstecz
    </v-btn>
    <v-btn
      class="is-primary is-outlined"
      v-if="!last"
      :loading="loading"
      :disabled="disableNextButton"
      @click="doAnswer()"
    >
      Dalej
    </v-btn>
    <v-btn class="is-primary is-outlined" v-else :loading="loading" :disabled="!disableNextButton" @click="doAnswer()">
      Zapisz
    </v-btn>
    <quiz-finish-button @finishExam="finishExam" :token="examDetails.token" v-if="canFinish"></quiz-finish-button>
  </div>
</template>

<script>
import EXAM_DETAILS_QUERY from '@/graphql/exams/query/TestToolExamDetailsQuery.gql';
import ANSWER_MUTATION from '@/graphql/exams/mutation/SendOpenAnswerMutation.gql';
import QuizFinishButton from './QuizFinishButton.vue';
import ShowFile from '@/pages/exam/ShowFile.vue';

export default {
  name: 'QuizOpenQuestion',
  components: { ShowFile, QuizFinishButton },
  data() {
    return {
      loading: false,
      answerContent: null,
      hasUnsaved: false,
    };
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    last: {
      type: Boolean,
      required: true,
    },
    userAnswer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    disableNextButton() {
      if (!this.answerContent) {
        return true;
      }

      return false;
    },
    canFinish() {
      if (this.hasUnsaved) {
        return false;
      }
      if (!this.examDetails.userAnswers) {
        return false;
      }
      return this.examDetails.canBeFinished;
    },
  },
  methods: {
    // resolveSelectedAnswer() {
    //   console.log('resolving');
    //   this.answerContent = this.selectedAnswer.userAnswerContent;
    // },

    finishExam() {
      this.doAnswer().then(() => {
        this.$emit('doFinishExam');
      });
    },

    doAnswer() {
      this.loading = true;
      const promise = this.$apollo.mutate({
        mutation: ANSWER_MUTATION,
        variables: {
          token: this.token,
          userAnswerId: this.userAnswer.id,
          answerContent: this.answerContent,
        },
        update: (store, { data: { exam } }) => {
          store.writeQuery({
            query: EXAM_DETAILS_QUERY,
            variables: { token: this.examDetails.token },
            data: { exam },
          });
        },
      });
      promise.then(() => {
        this.loading = false;
        this.hasUnsaved = false;
        this.$emit('changeUnsaved', false);
        this.$emit('changeQuestion', 1);
      });
      return promise;
    },
  },
  mounted() {
    this.answerContent = this.userAnswer.userAnswerContent;
  },
  apollo: {
    examDetails: {
      query: EXAM_DETAILS_QUERY,
      variables() {
        return {
          token: this.token,
        };
      },
      update: (data) => {
        return data.exam;
      },
    },
  },
};
</script>
<style scoped>
.question-image {
  width: auto;
  max-height: 300px;
}

.buttons .button {
  margin-right: 1rem;
}
</style>
